import React, { useState, useEffect, useCallback } from "react";
import axios from 'axios';
import { Chart, ChartType } from 'chart.js/auto';
import { DashboardComponents } from "../../../components";
import { ROUTES } from "../../../utils/routes";
import styles from "../dashboard.module.css";
import './dashboard.css';
import jsPDF from 'jspdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Product } from '../../../models/product';



const getCurrentDate = () => {
  const today = new Date();
  return today.toLocaleDateString('fr-FR');
};

const downloadChartAsImage = (chartId: string, imageFormat: 'png' | 'jpeg' = 'png') => {
  const canvas = document.getElementById(chartId) as HTMLCanvasElement;
  if (canvas) {
    const imageURI = canvas.toDataURL(`image/${imageFormat}`);
    const link = document.createElement('a');
    link.download = `${chartId}.${imageFormat}`;
    link.href = imageURI;
    link.click();
  }
};

const downloadChartAsPDF = (chartId: string, title: string) => {
  const canvas = document.getElementById(chartId) as HTMLCanvasElement;
  if (canvas) {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'landscape',
    });
    pdf.addImage(imgData, 'PNG', 10, 10, 280, 150);
    pdf.text(title, 10, 10);
    pdf.save(`${title}.pdf`);
  }
};

export const Dashboard = () => {
  const [totalProducts, setTotalProducts] = useState(0);
  const [newProductsCount, setNewProductsCount] = useState(0);
  const [modifiedProductsCount, setModifiedProductsCount] = useState(0);
  const [initialProducts, setInitialProducts] = useState<Product[]>([]);
  const [priceIncreaseCount, setPriceIncreaseCount] = useState(0);
  const [priceDecreaseCount, setPriceDecreaseCount] = useState(0);
  const [showDownloadButtons, setShowDownloadButtons] = useState(true);
  const [allCompanies, setAllCompanies] = useState<string[]>([]);
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);


  useEffect(() => {
    fetchProducts();
  }, [selectedCompany]); 
  
  const handleCompanyChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      setSelectedCompany(value === "All" ? null : value);
    },
    []
  );

  const fetchProducts = useCallback(async () => {
    try {
      const response = await axios.get('http://app.itrend-technology.tn/api/products');
      const data: Product[] = response.data;
  
      const filteredProducts = selectedCompany ? data.filter(product => product.Company === selectedCompany) : data;
      setFilteredProducts(filteredProducts);
  
      const allUniqueCompanies = Array.from(new Set(data.map(product => product.Company)));
      setAllCompanies(allUniqueCompanies);
      const today = new Date().setHours(0, 0, 0, 0);
      const uniqueProductRefs = new Set<string>();
      let newProductsSet = new Set<string>();
      let modifiedProductsSet = new Set<string>();
      let priceIncreases = 0;
      let priceDecreases = 0;
  
      filteredProducts.forEach((product: Product) => {
        uniqueProductRefs.add(product.Ref);
  
        if (product.DateAjout && new Date(product.DateAjout).setHours(0, 0, 0, 0) === today) {
          newProductsSet.add(product.Ref);
        }
  
        product.Modifications?.forEach((modification) => {
          const modDate = new Date(modification.dateModification).setHours(0, 0, 0, 0);
          if (modDate === today) {
            modifiedProductsSet.add(product.Ref);
            let nextPrice = parseFloat(product.Price.replace(/[^\d.,]/g, "").replace(',', '.'));
            const currentPrice = parseFloat(modification.ancienPrix.replace(/[^\d.,]/g, "").replace(',', '.'));
            if (currentPrice > nextPrice) {
              priceDecreases++;
            } else if (currentPrice < nextPrice) {
              priceIncreases++;
            }
          }
        });
      });
  
      let allModificationDates: Date[] = filteredProducts.flatMap(product =>
        product.Modifications?.map(modification => new Date(modification.dateModification)) ?? []
      );
      const uniqueDates = Array.from(new Set(allModificationDates.map(date => date.toISOString().slice(0, 10))))
        .sort((a: string, b: string) => new Date(b).getTime() - new Date(a).getTime())
        .slice(0, 7)
        .map((date: string) => new Date(date));
  
setTotalProducts(filteredProducts.length);
setNewProductsCount(newProductsSet.size);
setModifiedProductsCount(modifiedProductsSet.size);
setPriceIncreaseCount(priceIncreases);
setPriceDecreaseCount(priceDecreases);
setInitialProducts(filteredProducts);
setAllProducts(filteredProducts);

drawAvailabilityChart(filteredProducts);
drawAvailabilityByCategoryChart(filteredProducts);
drawMostModifiedProductsChart(filteredProducts);
drawCategoryDistributionChart(filteredProducts);
drawPriceChangesChart(filteredProducts, uniqueDates);
drawCombinedChangesAndNewProductsChart(filteredProducts, uniqueDates);
drawChart(calculateCategoryCounts(filteredProducts));
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }, [selectedCompany]);
  
  const calculateCategoryCounts = (products: Product[]): Record<string, number> => {
    const counts: Record<string, number> = {};
    products.forEach(product => {
      counts[product.Category] = (counts[product.Category] || 0) + 1;
    });
    return counts;
  };

   let availabilityChartInstances: Chart<"pie", number[], string>[] = [];

const drawAvailabilityByCategoryChart = useCallback((products: Product[]) => {
 const inStockCanvas = document.getElementById('inStockChart') as HTMLCanvasElement;
  const outOfStockCanvas = document.getElementById('outOfStockChart') as HTMLCanvasElement;
  const onOrderCanvas = document.getElementById('onOrderChart') as HTMLCanvasElement;

  availabilityChartInstances.forEach(instance => instance.destroy());
 const filteredProducts = selectedCompany ? products.filter(product => product.Company === selectedCompany) : products;

  const availabilityByCategory: Record<string, { inStock: number; outOfStock: number; onOrder: number; }> = {};
   filteredProducts.forEach(product => {
    const category = product.Category;
    if (!availabilityByCategory[category]) {
      availabilityByCategory[category] = { inStock: 0, outOfStock: 0, onOrder: 0 };
    }
    switch (product.Stock.toLowerCase()) {
      case "en stock":
      case "":
        availabilityByCategory[category].inStock++;
        break;
      case "hors stock":
      case "rupture de stock":
      case "en arrivage":
      case "en arrvage":
      case "en arrivge":
      case "En arriavge":
        availabilityByCategory[category].outOfStock++;
        break;
      case "sur commande":
      case "sur commande 48h":
      case "sur comande":
        availabilityByCategory[category].onOrder++;
        break;
    }
  });

 const categories = Object.keys(availabilityByCategory);
  const inStockData = categories.map(cat => availabilityByCategory[cat].inStock);
  const outOfStockData = categories.map(cat => availabilityByCategory[cat].outOfStock);
  const onOrderData = categories.map(cat => availabilityByCategory[cat].onOrder);

  if (inStockCanvas && outOfStockCanvas && onOrderCanvas) {
    const inStockCtx = inStockCanvas.getContext('2d');
    const outOfStockCtx = outOfStockCanvas.getContext('2d');
    const onOrderCtx = onOrderCanvas.getContext('2d');

   if (inStockCtx && outOfStockCtx && onOrderCtx) {
      const chartType: ChartType = "pie";

      availabilityChartInstances.push(new Chart<"pie", number[], string>(inStockCtx, {
        type: chartType,
        data: {
          labels: categories,
          datasets: [{
            data: inStockData,
            backgroundColor: ['#6b80bd', '#5887ba', '#143d8f', '#92bae8', '#b3daff', '#c1cad7', '#8da7be', '#48a6d9', '#969090']
          }]
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: 'Nombre des produits en stock par catégorie'
            }
          }
        }
      }));

      availabilityChartInstances.push(new Chart<"pie", number[], string>(outOfStockCtx, {
        type: chartType,
        data: {
          labels: categories,
          datasets: [{
            data: outOfStockData,
            backgroundColor: ['#6b80bd', '#5887ba', '#143d8f', '#92bae8', '#b3daff', '#c1cad7', '#8da7be', '#48a6d9', '#969090']
          }]
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: 'Nombre des produits hors stock par catégorie'
            }
          }
        }
      }));

      availabilityChartInstances.push(new Chart<"pie", number[], string>(onOrderCtx, {
        type: chartType,
        data: {
          labels: categories,
          datasets: [{
            data: onOrderData,
            backgroundColor: ['#6b80bd', '#5887ba', '#143d8f', '#92bae8', '#b3daff', '#c1cad7', '#8da7be', '#48a6d9', '#969090']
          }]
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: 'Nombre des produits sur commande par catégorie'
            }
          }
        }
      }));
    }
  }
}, []);
  
  let drawPriceIncreasesChartInstance: Chart | null = null;
  let drawPriceDecreasesChartInstance: Chart | null = null;

const drawPriceChangesChart = useCallback((products: Product[], recentDates: Date[]) => {
  if (drawPriceIncreasesChartInstance) {
    drawPriceIncreasesChartInstance.destroy();
  }
  if (drawPriceDecreasesChartInstance) {
    drawPriceDecreasesChartInstance.destroy();
  }


  const priceIncreasesPerDay: Record<string, number> = {};
  const priceDecreasesPerDay: Record<string, number> = {};

  const sortedRecentDates = recentDates.sort((a, b) => a.getTime() - b.getTime());

 sortedRecentDates.forEach(date => {
    const formattedDate = formatDate(date);
    priceIncreasesPerDay[formattedDate] = 0;
    priceDecreasesPerDay[formattedDate] = 0;
  });

  products.forEach((product: Product) => {
    product.Modifications?.forEach((modification) => {
      const modDateStr = formatDate(new Date(modification.dateModification));
      if (sortedRecentDates.some(date => formatDate(date) === modDateStr)) {
       const previousPrice = parseFloat(modification.ancienPrix.replace(/[^\d.,]/g, "").replace(',', '.'));
        let currentPrice = previousPrice;
        if (modification.nouveauPrix) {
          currentPrice = parseFloat(modification.nouveauPrix.replace(/[^\d.,]/g, "").replace(',', '.'));
        }

        if (previousPrice < currentPrice) {
          priceIncreasesPerDay[modDateStr]++;
        } else if (previousPrice > currentPrice) {
          priceDecreasesPerDay[modDateStr]++;
        }
      }
    });
  });

 const sortedDates = Object.keys(priceIncreasesPerDay).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

 const ctxIncrease = document.getElementById('priceIncreasesChart') as HTMLCanvasElement;
  const ctxDecrease = document.getElementById('priceDecreasesChart') as HTMLCanvasElement;

 if (ctxIncrease && ctxDecrease) {
     drawPriceIncreasesChartInstance = new Chart(ctxIncrease, {
      type: 'line',
      data: {
        labels: sortedDates,
        datasets: [{
          label: 'Nombre de produits',
          data: sortedDates.map(date => priceIncreasesPerDay[date]),
          borderColor: '#143d8f',
          fill: false
        }]
      },
      options: {
        animation: {
          duration: 2000,
          easing: 'easeInOutBounce'
        },
        scales: {
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            display: true
          },
          title: {
            display: true,
            text: 'Produits avec Augmentations de prix'
          }
        }
      }
    });

     drawPriceDecreasesChartInstance = new Chart(ctxDecrease, {
      type: 'line',
      data: {
        labels: sortedDates,
        datasets: [{
          label: 'Nombre de produits',
          data: sortedDates.map(date => priceDecreasesPerDay[date]),
          borderColor: '#143d8f',
          fill: false
        }]
      },
      options: {
        animation: {
          duration: 2000,
          easing: 'easeInOutBounce'
        },
        scales: {
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            display: true
          },
          title: {
            display: true,
            text: 'Produits avec Diminutions de prix'
          }
        }
      }
    });
  }
}, []);


let drawCombinedChangesAndNewProductsChartInstances: Chart | null = null;

const drawCombinedChangesAndNewProductsChart = useCallback((products: Product[], recentDates: Date[]) => {
 
  if (drawCombinedChangesAndNewProductsChartInstances) {
    drawCombinedChangesAndNewProductsChartInstances.destroy();
  }
  const modificationsAndNewProductsPerDay: Record<string, { modifications: number; newProducts: number }> = {};
  recentDates.forEach(date => {
    const formattedDate = formatDate(date);
    modificationsAndNewProductsPerDay[formattedDate] = { modifications: 0, newProducts: 0 };
  });
  products.forEach(product => {
    const ajoutDate = product.DateAjout ? formatDate(new Date(product.DateAjout).toDateString()) : "";
    product.Modifications?.forEach((modification) => {
      const modDateStr = formatDate(new Date(modification.dateModification));
      if (recentDates.some(date => formatDate(date) === modDateStr)) {
        modificationsAndNewProductsPerDay[modDateStr].modifications++;
      }
    });
    if (ajoutDate && recentDates.some(date => formatDate(date) === ajoutDate)) {
      modificationsAndNewProductsPerDay[ajoutDate].newProducts++;
    }
  });

  const sortedDates = Object.keys(modificationsAndNewProductsPerDay).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  const modificationsData = sortedDates.map(date => modificationsAndNewProductsPerDay[date].modifications);
  const newProductsData = sortedDates.map(date => modificationsAndNewProductsPerDay[date].newProducts);

  const ctx = document.getElementById('combinedChangesAndNewProductsChart') as HTMLCanvasElement;
  if (ctx) {
    drawCombinedChangesAndNewProductsChartInstances = new Chart(ctx, {
      type: 'line',
      data: {
        labels: sortedDates,
        datasets: [{
          label: 'Produits Modifiés',
          data: modificationsData,
          borderColor: '#143d8f',
          fill: false
        }, {
          label: 'Nouveaux Produits',
          data: newProductsData,
          borderColor: '#48a6d9',
          fill: false
        }]
      },
      options: {
        animation: {
          duration: 2000, 
          easing: 'easeInOutBounce' 
        },
        scales: {
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            display: true
          },
          title: {
            display: true,
            text: 'Produits Modifiés et Nouveaux Produits par Jour'
          }
        }
      }
    });
  }
}, []);



  
let AvailabilityChartInstance: Chart | null = null;


const drawAvailabilityChart = useCallback((products: Product[]) => {
  if (AvailabilityChartInstance) {
    AvailabilityChartInstance.destroy();
  }

  const competitorsStats: Record<string, { inStock: number, outOfStock: number, onOrder: number }> = {};
  products.forEach(product => {
    if (!competitorsStats[product.Company]) {
      competitorsStats[product.Company] = { inStock: 0, outOfStock: 0, onOrder: 0 };
    }
    if (product.Stock === "En stock" || product.Stock === "") {
      competitorsStats[product.Company].inStock++;
    } else if (product.Stock === "Sur commande" || product.Stock ==="sur comande" || product.Stock === "sur commande 48h") {
      competitorsStats[product.Company].onOrder++;
    } else {
      competitorsStats[product.Company].outOfStock++;
    }
  });

  const ctx = document.getElementById('competitorChart') as HTMLCanvasElement;
  if (ctx) {
    AvailabilityChartInstance = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: Object.keys(competitorsStats),
        datasets: [{
          label: 'En stock',
          data: Object.values(competitorsStats).map(stats => stats.inStock),
          backgroundColor: '#143d8f'
        }, {
          label: 'Hors stock',
          data: Object.values(competitorsStats).map(stats => stats.outOfStock),
          backgroundColor: '#48a6d9'
        }, {
          label: 'Sur commande',
          data: Object.values(competitorsStats).map(stats => stats.onOrder),
          backgroundColor: '#6b80bd'
        }]
      },
      options: {
        animation: {
          duration: 2000, 
          easing: 'easeInOutBounce' 
        },
        scales: {
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Disponibilité des Produits par concurrent'
          }
        }
      }
    });
  }
},[]);


    const formatDate = (date: Date | string): string => {
      return new Date(date).toLocaleDateString('fr-FR', {
          year: 'numeric', month: 'long', day: 'numeric'
      });
  };
  let drawChartInstance: Chart<'pie', number[], string> | null = null; 
  const drawChart = useCallback((categoryCounts: Record<string, number>) => {
    const ctx = document.getElementById('categoryChart') as HTMLCanvasElement | null;
    if (ctx) {
      const chartContext = ctx.getContext('2d');
      if (chartContext) {
        if (drawChartInstance) {
          drawChartInstance.destroy();
        }

        // Créer une nouvelle instance du graphique
        drawChartInstance = new Chart(chartContext, {
          type: 'pie',
          data: {
            labels: Object.keys(categoryCounts),
            datasets: [{
              data: Object.values(categoryCounts),
              backgroundColor: ['#6b80bd','#5887ba','#143d8f','#92bae8','#b3daff','#c1cad7','#8da7be','#48a6d9','#969090'],
              hoverBackgroundColor: ['#6b80bd','#5887ba','#143d8f','#92bae8','#b3daff','#c1cad7','#8da7be','#48a6d9','#969090']
            }]
          },
          options: {
            animation: {
              duration: 2000,
              easing: 'easeInOutBounce'
            },
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Distribution des Produits Par Catégorie'
              }
            }
          }
        });
      }
    }
  }, []);

const drawCategoryDistributionChart = useCallback((products: Product[]) => {
  let categoryCounts: Record<string, number>;
  if (selectedCompany) {
    const filteredProducts = products.filter(product => product.Company === selectedCompany);
    categoryCounts = calculateCategoryCounts(filteredProducts);
  } else {
    categoryCounts = calculateCategoryCounts(products);
  }

  drawChart(categoryCounts);
}, [selectedCompany, drawChart]);
 
let mostModifiedProductsChartInstance: Chart | null = null;
const drawMostModifiedProductsChart = useCallback((products: Product[]) => {
 
  if (mostModifiedProductsChartInstance) {
    mostModifiedProductsChartInstance.destroy();
  }

  const productsWithMostModifications = products
    .filter(product => product.Modifications && product.Modifications.length > 0)
    .sort((a, b) => b.Modifications!.length - a.Modifications!.length)
    .slice(0, 5);

  const productLabels = productsWithMostModifications.map(product => product.Ref);
  const modificationCounts = productsWithMostModifications.map(product => product.Modifications!.length);

  const ctx = document.getElementById('mostModifiedProductsChart') as HTMLCanvasElement;
  if (ctx) {
    mostModifiedProductsChartInstance = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: productLabels,
        datasets: [{
          label: 'Nombre modifications ',
          data: modificationCounts,
          backgroundColor: Array(productLabels.length).fill('#13367E')
        }]
      },
      options: {
        animation: {
          duration: 2000,
          easing: 'easeInOutBounce'
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1
            }
          }
        },
        plugins: {
          legend: {
            display: true
          },
          title: {
            display: true,
            text: 'Top 5 des Produits avec le Plus de Modifications'
          }
        }
      }
    });
  }
}, []);

    const availableProductsCount = initialProducts.filter(product => ( product.Stock.toLowerCase() === "en stock" || product.Stock === "" )
      && 
      (!selectedCompany || product.Company === selectedCompany)
    ).length;
    
    const validUnavailableStatuses = new Set([
      "sur commande",
      "sur comande",
      "sur commande 48h"
    ]);
    
    const unavailableProductsCount = initialProducts.filter(product => {
      const stockStatus = product.Stock.toLowerCase();
      return validUnavailableStatuses.has(stockStatus) && 
             (!selectedCompany || product.Company === selectedCompany);
    }).length;
    
    
    const validOutOfStockStatuses = new Set([
      "hors stock", 
      "rupture de stock",
      "en arriavge",
      "en arrivage", 
      "en arrvage", 
      "en arrivge"
    ]);
    
    const horsstockProductsCount = initialProducts.filter(product => {
      const stockStatus = product.Stock.toLowerCase();
      return validOutOfStockStatuses.has(stockStatus) && 
             (!selectedCompany || product.Company === selectedCompany);
    }).length;

    return (
      
      <div className={styles.dashboard_content}>
        <div className={styles.dashboard_content_container}>
          <div className={styles.dashboard_cards}>
            <DashboardComponents.StatCard
              title="Tous les Produits"
              link={ROUTES.PRODUCTS}
              value={totalProducts}
              icon="/icons/product.svg"
            />
           <DashboardComponents.StatCard
              title={`Nouveaux Produits (${getCurrentDate()})`}
              value={newProductsCount}
              link={ROUTES.NEWPRODUCTS}
              icon="/icons/new.svg"
            />
            <DashboardComponents.StatCard
              title={`Produits Modifiés (${getCurrentDate()})`}
              value={modifiedProductsCount}
              link={ROUTES.UPDATE}
              icon="/icons/update.svg"
              increaseCount={priceIncreaseCount} 
              decreaseCount={priceDecreaseCount} 
            />
            <DashboardComponents.StatCard
              title="Produits En Stock"
              value={availableProductsCount}
              icon="/icons/product.svg"
            />
            <DashboardComponents.StatCard
              title="Produits Hors stock"
              value={horsstockProductsCount}
              icon="/images/suppression.png"
            />
            <DashboardComponents.StatCard
              title="Produits sur commandes"
              value={unavailableProductsCount}
              icon="/icons/product.svg"
            />
    </div>  
           
    <div>
      <div>
       
     <div>
      <label htmlFor="companySelector">Sélectionnez un concurrent: </label>
      <select id="companySelector" onChange={handleCompanyChange} className="select_company">
        <option value="All">Toutes les concurrents</option>
        {allCompanies.map((company, index) => (
          <option key={index} value={company}>{company}</option>
        ))}
      </select>
    </div>
      </div>
</div>
<div className="row">
           <div className="graph-container">
        <div className="canvas-wrapper">
          <canvas id="competitorChart" width="700" height="500"></canvas>
          {showDownloadButtons && (
            <div className="vertical-icon-container">
              <FontAwesomeIcon 
                icon={faImage} 
                onClick={() => downloadChartAsImage('competitorChart', 'png')} 
                className="icon-button" 
                style={{ cursor: 'pointer' }} 
                title="Download as Image"
              />
              <FontAwesomeIcon 
                icon={faFilePdf} 
                onClick={() => downloadChartAsPDF('competitorChart', 'Competitor Chart')} 
                className="icon-button" 
                style={{ cursor: 'pointer' }} 
                title="Download as PDF"
              />
            </div>
          )}
        </div>
      </div>

      <div className="graph-container">
        <div className="canvas-wrapper">
          <canvas id="mostModifiedProductsChart" width="700" height="500"></canvas>
          {showDownloadButtons && (
            <div className="vertical-icon-container">
              <FontAwesomeIcon 
                icon={faImage} 
                onClick={() => downloadChartAsImage('mostModifiedProductsChart', 'png')} 
                className="icon-button" 
                style={{ cursor: 'pointer' }} 
                title="Download as Image"
              />
              <FontAwesomeIcon 
                icon={faFilePdf} 
                onClick={() => downloadChartAsPDF('mostModifiedProductsChart', '')} 
                className="icon-button" 
                style={{ cursor: 'pointer' }} 
                title="Download as PDF"
              />
            </div>
          )}
        </div>
      </div>

  <div className="graph-container" >
  <div className="canvas-wrapper">
        <canvas id="categoryChart" width="700" height="500"></canvas>
        {showDownloadButtons && (
          <div className="vertical-icon-container">
            <FontAwesomeIcon 
              icon={faImage} 
              onClick={() => downloadChartAsImage('categoryChart', 'png')} 
              className="icon-button" 
              style={{ cursor: 'pointer' }} 
              title="Download as Image"
            />
            <FontAwesomeIcon 
              icon={faFilePdf} 
              onClick={() => downloadChartAsPDF('categoryChart', '')} 
              className="icon-button" 
              style={{ cursor: 'pointer' }} 
              title="Download as PDF"
            />
          </div>
        )}
      </div>
      </div>
      </div>


 <div className="row">
  <div className="graph-container">
   <div className="canvas-wrapper">
    <canvas id="combinedChangesAndNewProductsChart" width="700" height="500" style={{ alignContent: 'center' }}></canvas>
    {!selectedCompany && showDownloadButtons && (
      <div className="vertical-icon-container">
        <FontAwesomeIcon
          icon={faImage}
          onClick={() => downloadChartAsImage('combinedChangesAndNewProductsChart', 'png')}
          className="icon-button"
          style={{ cursor: 'pointer' }}
          title="Download as Image"
        />
        <FontAwesomeIcon
          icon={faFilePdf}
          onClick={() => downloadChartAsPDF('combinedChangesAndNewProductsChart', '')}
          className="icon-button"
          style={{ cursor: 'pointer' }}
          title="Download as PDF"
        />
      </div>
    )}
  </div>
</div>
  

 <div className="graph-container">
   <div className="canvas-wrapper">
    <canvas id="priceIncreasesChart" width="700" height="500"></canvas>
    {showDownloadButtons && (
      <div className="vertical-icon-container">
        <FontAwesomeIcon 
          icon={faImage} 
          onClick={() => downloadChartAsImage('priceIncreasesChart', 'png')} 
          className="icon-button" 
          style={{ cursor: 'pointer' }} 
          title="Download as Image"
        />
        <FontAwesomeIcon 
          icon={faFilePdf} 
          onClick={() => downloadChartAsPDF('priceIncreasesChart', 'Price Increases Chart')} 
          className="icon-button" 
          style={{ cursor: 'pointer' }} 
          title="Download as PDF"
        />
      </div>
    )}
  </div>
</div>

<div className="graph-container">
  <div className="canvas-wrapper">
    <canvas id="priceDecreasesChart" width="700" height="500"></canvas>
    {showDownloadButtons && (
      <div className="vertical-icon-container">
        <FontAwesomeIcon 
          icon={faImage} 
          onClick={() => downloadChartAsImage('priceDecreasesChart', 'png')} 
          className="icon-button" 
          style={{ cursor: 'pointer' }} 
          title="Download as Image"
        />
        <FontAwesomeIcon 
          icon={faFilePdf} 
          onClick={() => downloadChartAsPDF('priceDecreasesChart', 'Price Decreases Chart')} 
          className="icon-button" 
          style={{ cursor: 'pointer' }} 
          title="Download as PDF"
        />
      </div>
    )}
  </div>
</div>
</div>

<div className="row">
<div className="graph-container">
  <div className="canvas-wrapper">
    <canvas id="inStockChart" width="700" height="500"></canvas>
    {showDownloadButtons && (
      <div className="vertical-icon-container">
        <FontAwesomeIcon 
          icon={faImage} 
          onClick={() => downloadChartAsImage('inStockChart', 'png')} 
          className="icon-button" 
          style={{ cursor: 'pointer' }} 
          title="Download as Image"
        />
        <FontAwesomeIcon 
          icon={faFilePdf} 
          onClick={() => downloadChartAsPDF('inStockChart', 'inStockChart')} 
          className="icon-button" 
          style={{ cursor: 'pointer' }} 
          title="Download as PDF"
        />
      </div>
    )}
  </div>
</div>


<div className="graph-container">
  <div className="canvas-wrapper">
    <canvas id="outOfStockChart" width="700" height="500"></canvas>
    {showDownloadButtons && (
      <div className="vertical-icon-container">
        <FontAwesomeIcon 
          icon={faImage} 
          onClick={() => downloadChartAsImage('outOfStockChart', 'png')} 
          className="icon-button" 
          style={{ cursor: 'pointer' }} 
          title="Download as Image"
        />
        <FontAwesomeIcon 
          icon={faFilePdf} 
          onClick={() => downloadChartAsPDF('outOfStockChart', 'outOfStockChart')} 
          className="icon-button" 
          style={{ cursor: 'pointer' }} 
          title="Download as PDF"
        />
      </div>
    )}
  </div>
</div>




<div className="graph-container">
  <div className="canvas-wrapper">
    <canvas id="onOrderChart" width="700" height="500"></canvas>
    {showDownloadButtons && (
      <div className="vertical-icon-container">
        <FontAwesomeIcon 
          icon={faImage} 
          onClick={() => downloadChartAsImage('onOrderChart', 'png')} 
          className="icon-button" 
          style={{ cursor: 'pointer' }} 
          title="Download as Image"
        />
        <FontAwesomeIcon 
          icon={faFilePdf} 
          onClick={() => downloadChartAsPDF('onOrderChart', 'onOrderChart')} 
          className="icon-button" 
          style={{ cursor: 'pointer' }} 
          title="Download as PDF"
        />
      </div>
    )}
  </div>
</div>
</div>
</div>
</div>
      
    );
  };

  export default Dashboard;
      
  