// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_cards {
    gap: 1em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto; 
      }`, "",{"version":3,"sources":["webpack://./src/layout/dashboard/NewProducts/newproducts.css"],"names":[],"mappings":"AAAA;IACI,QAAQ;IACR,aAAa;IACb,qCAAqC;IACrC,6BAA6B;MAC3B","sourcesContent":[".dashboard_cards {\r\n    gap: 1em;\r\n    display: grid;\r\n    grid-template-columns: repeat(2, 1fr);\r\n    grid-template-rows: auto auto; \r\n      }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
