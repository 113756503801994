export class ROUTES {
  static readonly LOGIN = "/";
  static readonly DASHBOARD = "/dashboard";
  static readonly COMPETITORS = "/dashboard/competitors";
  static readonly PRODUCTS = "/dashboard/products";
  static readonly NEWPRODUCTS = "/dashboard/newproduct";
  static readonly UPDATE = "/dashboard/update";
  static readonly DELETEDPRODUCTS = "/dashboard/deletedproducts";
  static readonly PRODUCTDETAILS = "/dashboard/productdetails";
  static readonly Home = "/home";
  static readonly SignUp = "/signUp";
}
