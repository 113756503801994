import React, { useCallback, useEffect, useState } from 'react';
import { Product } from '../../../models/product';
import axios from 'axios';
import styles from '../dashboard.module.css';
import { Input } from '@components';
import { DashboardComponents } from '@components';
import * as XLSX from "xlsx"; 
import ProductDetails from '../ProductDetails';
import { FaExclamationCircle,FaArrowUp, FaArrowDown } from 'react-icons/fa';




const Update: React.FC = () => {
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [products, setProducts] = useState<Product[]>([]);
  const [loadingProducts, setLoadingProducts] = useState<boolean>(true);
  const [minPrice, setMinPrice] = useState<string>('');
  const [maxPrice, setMaxPrice] = useState<string>('');
  const [priceFilter, setPriceFilter] = useState<string | null>(null);
  const [displayMode, setDisplayMode] = useState<'table' | 'box'>('table');
  const [availabilityFilter, setAvailabilityFilter] = useState<string | null>(null);
  const [fetched, setFetched] = useState<number>(0);
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null); 
  const [initialProducts, setInitialProducts] = useState<Product[]>([]);
  const companyOptions = Array.from(new Set(initialProducts.map(product => product.Company)));
  const [dateFilter, setDateFilter] = useState<string | null>('jour');
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [priceChangeType, setPriceChangeType] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const handleProductClick = (product: Product) => {
    setSelectedProduct(product);
  };
  useEffect(() => {
    fetchProducts();
    setFetched(50);
  }, [page, pageSize]);

  useEffect(() => {
    applyFilters();
  }, [initialProducts, availabilityFilter, minPrice, maxPrice, priceFilter, dateFilter, search, selectedCompany, priceChangeType]);
  

  const applyFilters = useCallback(() => {
    let filteredProducts = initialProducts;
    const meetsPriceFilter = !priceFilter || priceFilter === 'asc' || priceFilter === 'desc';
  

    if (selectedCompany) {
      filteredProducts = filteredProducts.filter(product => product.Company === selectedCompany);
    }

    if (minPrice || maxPrice) {
      filteredProducts = filteredProducts.filter(product => {
        const price = parseFloat(product.Price);
        return (!minPrice || price >= parseFloat(minPrice)) &&
               (!maxPrice || price <= parseFloat(maxPrice));
      });
    }


    if (availabilityFilter) {
      filteredProducts = filteredProducts.filter(product => {
        switch (availabilityFilter) {
          case 'available':
            return product.Stock === "En stock" || product.Stock === "";
          case 'unavailable':
            return ["Sur comande", "sur comande", "sur commande","Sur Commande","Sur commande"].includes(product.Stock);
          case 'horstock':
            return ["En arrivage", "en arrivage", "en arrvage", "en arrivge", "Rupture de stock", "Hors stock"].includes(product.Stock);
          default:
            return true;
        }
      });
    }
  
    if (search) {
      filteredProducts = filteredProducts.filter(product => {
        return product.Ref.toLowerCase().includes(search.toLowerCase()) ||
               product.Designation.toLowerCase().includes(search.toLowerCase()) ||
               product.Stock.toLowerCase().includes(search.toLowerCase()) ||
               product.Company.toLowerCase().includes(search.toLowerCase()) ||
               product.Brand.toLowerCase().includes(search.toLowerCase());
      });
    }

    if (priceChangeType) {
      filteredProducts = filteredProducts.filter(product => {
        const priceChange = calculatePriceChange(product);
        return (priceChangeType === 'increase' && priceChange > 0) || 
               (priceChangeType === 'decrease' && priceChange < 0);
      });
    }
    if (dateFilter) {
      filteredProducts = filterProductsByDateRange(filteredProducts);
    }

    filteredProducts = filterProductsByDateRange(filteredProducts);

    filteredProducts = applyPriceFilter(filteredProducts);

    setProducts(filteredProducts);
    return meetsPriceFilter;
  }, [initialProducts, selectedCompany, minPrice, maxPrice, availabilityFilter, search, priceChangeType, dateFilter, priceFilter]);

 
  const fetchProducts = useCallback(() => {
    setLoadingProducts(true);
    axios
      .get('http://app.itrend-technology.tn/api/products', {
        params: {
          page,
          pageSize,
        },
      })
      .then((response) => {
        const products: Product[] = response.data;
        const uniqueProductRefs = new Set<string>();
        products.forEach((product: Product) => {
          uniqueProductRefs.add(product.Ref);
        });
  
        const filteredProducts = products.filter((product: Product) => product.Modifications && product.Modifications.length > 0);
  
        setProducts(filteredProducts);
        setInitialProducts(filteredProducts);
        setLoadingProducts(false);
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
        setLoadingProducts(false);
      });
  },  [page, pageSize, setLoadingProducts, setProducts, setInitialProducts]);
  
  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSearch(value);
      if (value === '') {
        setFetched(50);
        setProducts(initialProducts);
      } else {
        const filteredProducts = initialProducts.filter(
          (product: Product) =>
            product.Ref.toLowerCase().includes(value.toLowerCase()) ||
            product.Designation.toLowerCase().includes(value.toLowerCase()) ||
            product.Stock.toLowerCase().includes(value.toLowerCase()) ||
            product.Company.toLowerCase().includes(value.toLowerCase()) ||
            product.Brand.toLowerCase().includes(value.toLowerCase())
        );
        setProducts(filteredProducts);
      }
    },
    [initialProducts]
  );


  const exportToXLS = useCallback(() => {
    let filteredProducts = initialProducts.filter(product => {
        const priceValid = parseFloat(product.Price.replace(/\s/g, '').replace(',', '.'));
        const meetsPriceCriteria = (!minPrice || priceValid >= parseFloat(minPrice.replace(/\s/g, '').replace(',', '.'))) &&
                                   (!maxPrice || priceValid <= parseFloat(maxPrice.replace(/\s/g, '').replace(',', '.')));

                                   const meetsStockCriteria = !availabilityFilter ||
                                   (availabilityFilter === "available" && 
                                       (product.Stock === "En stock" || product.Stock === "")) ||
                                   (availabilityFilter === "unavailable" && 
                                       (product.Stock === "Sur commande" || product.Stock === "sur comande" || product.Stock === "Sur comande"|| 
                                        product.Stock === "Sur Commande" || product.Stock === "sur commande")) ||
                                   (availabilityFilter === "horstock" && 
                                       (product.Stock === "Hors stock" || product.Stock === "Rupture de stock" || 
                                        product.Stock === "En arrivage" || product.Stock === "en arrivage" || 
                                        product.Stock === "en arrvage" || product.Stock === "en arrivge"));
                               
        const meetsCompanyCriteria = !selectedCompany || selectedCompany === "All" || product.Company === selectedCompany;
        
        const meetsDateCriteria = !dateFilter || filterProductsByDateRange([product]).length > 0;
        
        const meetsSearchCriteria = !search || 
                                    product.Ref.toLowerCase().includes(search.toLowerCase()) ||
                                    product.Designation.toLowerCase().includes(search.toLowerCase()) ||
                                    product.Stock.toLowerCase().includes(search.toLowerCase()) ||
                                    product.Brand.toLowerCase().includes(search.toLowerCase()) ||
                                    product.Company.toLowerCase().includes(search.toLowerCase());

       const meetsPriceChangeCriteria = !priceChangeType || 
                                    (priceChangeType === "increase" && product.Modifications && product.Modifications.some(mod => {
                                      const lastPrice = parseFloat(mod.ancienPrix.replace(/\s/g, '').replace(',', '.'));
                                      const currentPrice = parseFloat(product.Price.replace(/\s/g, '').replace(',', '.'));
                                      return currentPrice > lastPrice;
                                    })) ||
                                    (priceChangeType === "decrease" && product.Modifications && product.Modifications.some(mod => {
                                      const lastPrice = parseFloat(mod.ancienPrix.replace(/\s/g, '').replace(',', '.'));
                                      const currentPrice = parseFloat(product.Price.replace(/\s/g, '').replace(',', '.'));
                                      return currentPrice < lastPrice;
                                    }));
                                          
        return meetsPriceCriteria && meetsStockCriteria && meetsCompanyCriteria && meetsDateCriteria && meetsSearchCriteria && meetsPriceChangeCriteria;
    });

    if (priceFilter === 'asc') {
        filteredProducts.sort((a, b) => parseFloat(a.Price.replace(/\s/g, '').replace(',', '.')) - parseFloat(b.Price.replace(/\s/g, '').replace(',', '.')));
    } else if (priceFilter === 'desc') {
        filteredProducts.sort((a, b) => parseFloat(b.Price.replace(/\s/g, '').replace(',', '.')) - parseFloat(a.Price.replace(/\s/g, '').replace(',', '.')));
    }

    const data = filteredProducts.map(product => {
        const lastModification = product.Modifications && product.Modifications[product.Modifications.length - 1];
        return {
            Ref: product.Ref,
            Image: product.Image,
            Designation: product.Designation,
            DateAjout: product.DateAjout ? new Date(product.DateAjout).toLocaleDateString() : 'N/A',
            Price: product.Price,
            DiscountAmount: product.DiscountAmount,
            Stock: product.Stock,
            Brand: product.Brand,
            BrandImage: product.BrandImage,
            Company: product.Company,
            Category: product.Category,
            Subcategory: product.Subcategory,
            Link: product.Link,
            AncienPrix: lastModification ? lastModification.ancienPrix : 'N/A',
            DateModification: lastModification ? new Date(lastModification.dateModification).toLocaleDateString() : 'N/A'
        };
    });

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ProduitsFiltrés");
    XLSX.writeFile(wb, `ProduitsFiltrés${new Date().toISOString().split('T')[0]}.xlsx`);
}, [initialProducts, minPrice, maxPrice, selectedCompany, availabilityFilter, priceFilter, dateFilter, search, priceChangeType]);

const handleMinPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const value = event.target.value;
  setMinPrice(value);

  if (maxPrice && parseFloat(value) > parseFloat(maxPrice)) {
    setErrorMessage('Le prix minimum ne peut pas être supérieur au prix maximum.');
  } else {
    filterProducts(value, maxPrice, 'min');
    setErrorMessage('');
  }
};

const handleMaxPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const value = event.target.value;
  setMaxPrice(value);

  if (minPrice && parseFloat(value) < parseFloat(minPrice)) {
    setErrorMessage('Le prix maximum ne peut pas être inférieur au prix minimum.');
  } else {
    filterProducts(minPrice, value, 'max');
    setErrorMessage('');
  }
};

  const filterProducts = (min: string, max: string, filterType: 'min' | 'max') => {
    const minPriceValue = parseFloat(min.replace(/\s/g, '').replace(',', '.'));
    const maxPriceValue = parseFloat(max.replace(/\s/g, '').replace(',', '.'));

    const filteredProducts = initialProducts.filter((product: Product) => {
      const price = parseFloat(product.Price.replace(/\s/g, '').replace(',', '.'));

      if (filterType === 'min') {
        return price >= minPriceValue;
      } else if (filterType === 'max') {
        return price <= maxPriceValue;
      }

      return true;
    });

    setProducts(filteredProducts);
  };


  const handlePriceFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      setPriceFilter(value === 'asc' || value === 'desc' ? value : null);
    },
    []
  );
  
  const handleAvailabilityFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      setAvailabilityFilter(value);
      
      let filteredProducts = initialProducts;
      if (value === "available") {
        filteredProducts = initialProducts.filter(product => 
          product.Stock === "En stock" || product.Stock === ""
        );
      } else if (value === "unavailable") {
        filteredProducts = initialProducts.filter(product => 
          product.Stock === "Sur commande" || product.Stock === "sur comande" || product.Stock === "Sur comande"|| 
          product.Stock === "Sur Commande" || product.Stock === "sur commande"
        );
      } else if (value === "horstock") {
        filteredProducts = initialProducts.filter(product => 
          product.Stock === "Hors stock" || product.Stock === "Rupture de stock" || 
          product.Stock === "En arrivage" || product.Stock === "en arrivage" || 
          product.Stock === "en arrvage" || product.Stock === "en arrivge"
        );
      }
      setProducts(filteredProducts);
    },
    [initialProducts]
  );
  
  

  const applyPriceFilter = (filteredProducts: Product[]) => {
    const sortedProducts = [...filteredProducts];
    if (priceFilter === 'asc') {
      return sortedProducts.sort((a, b) => parseFloat(a.Price.replace(/\s/g, '').replace(',', '.')) - parseFloat(b.Price.replace(/\s/g, '').replace(',', '.')));
    } else if (priceFilter === 'desc') {
      return sortedProducts.sort((a, b) => parseFloat(b.Price.replace(/\s/g, '').replace(',', '.')) - parseFloat(a.Price.replace(/\s/g, '').replace(',', '.')));
    } else {
      return sortedProducts;
    }
  };

  const __handleLoadMore = () => {
    if (products.length > fetched) {
      const newFetched = fetched + 50;
      setFetched(newFetched);
    }
  };
  const resetFilters = useCallback(() => {
    setSearch("");
    setPage(1);
    setPageSize(50);
    setProducts(initialProducts);
    setPriceFilter(null);
    setMinPrice("");
    setMaxPrice("");
    setAvailabilityFilter(null);
    setSelectedCompany(null);
    setDateFilter('jour');
    setPriceChangeType(null); 
    fetchProducts();
  }, [initialProducts, fetchProducts]);
  

  const handleResetFilters = () => {
    resetFilters();
  };
  
  const handleCompanyChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      setSelectedCompany(value === "All" ? null : value);
      
      const filteredProducts = value === "All"
        ? initialProducts
        : initialProducts.filter(product => product.Company === value);
      
      const filteredProductsByOtherFilters = applyOtherFilters(filteredProducts);
      
      setProducts(filteredProductsByOtherFilters);
    },
    [initialProducts, search, priceFilter, maxPrice, minPrice, availabilityFilter]
  );
  const applyOtherFilters = (filteredProducts: Product[]) => {
    const filteredByDate = filterProductsByDateRange(filteredProducts);
    const filteredBySearch = filteredByDate.filter(product => 
      product.Ref.toLowerCase().includes(search.toLowerCase()) ||
      product.Designation.toLowerCase().includes(search.toLowerCase()) ||
      product.Stock.toLowerCase().includes(search.toLowerCase()) ||
      product.Company.toLowerCase().includes(search.toLowerCase()) ||
      product.Brand.toLowerCase().includes(search.toLowerCase())
    );
    
    const filteredByOtherFilters = filteredBySearch.filter((product) => {
      const meetsPriceCriteria =
        (!minPrice || parseFloat(product.Price.replace(/\s/g, '').replace(',', '.')) >= parseFloat(minPrice.replace(/\s/g, '').replace(',', '.'))) &&
        (!maxPrice || parseFloat(product.Price.replace(/\s/g, '').replace(',', '.')) <= parseFloat(maxPrice.replace(/\s/g, '').replace(',', '.')));
  
        const meetsStockCriteria = !availabilityFilter ||
        (availabilityFilter === "available" && 
            (product.Stock === "En stock" || product.Stock === "")) ||
        (availabilityFilter === "unavailable" && 
            (product.Stock === "Sur commande" || product.Stock === "sur comande" || product.Stock === "Sur comande"|| 
              product.Stock === "Sur Commande" || product.Stock === "sur commande")) ||
        (availabilityFilter === "horstock" && 
            (product.Stock === "Hors stock" || product.Stock === "Rupture de stock" || 
             product.Stock === "En arrivage" || product.Stock === "en arrivage" || 
             product.Stock === "en arrvage" || product.Stock === "en arrivge"));
    
      const meetsPriceFilter = !priceFilter || priceFilter === 'asc' || priceFilter === 'desc';
  
      const meetsDateFilter = !dateFilter || dateFilter === 'jour' || dateFilter === 'semaine' || dateFilter === 'mois';
  
      return meetsPriceCriteria && meetsStockCriteria && meetsPriceFilter && meetsDateFilter;
    });
  
    const sortedProducts = applyPriceFilter(filteredByOtherFilters);
  
    return sortedProducts;
  };
    
  const calculatePriceChange = (product: Product) => {
    if (!product.Modifications || product.Modifications.length === 0) return 0;
    const lastModification = product.Modifications[product.Modifications.length - 1];
    const oldPrice = parseFloat(lastModification.ancienPrix.replace(/\s/g, '').replace(',', '.'));
    const newPrice = parseFloat(product.Price.replace(/\s/g, '').replace(',', '.'));
    const priceChange = newPrice - oldPrice;
    console.log(`Price change for ${product.Ref}:`, priceChange);
    return Math.round(priceChange * 100) / 100;
  };
  
  const handlePriceChangeFilter = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const changeType = event.target.value;
      const filteredProducts = initialProducts.filter((product) => {
        const meetsPriceCriteria =
          (!minPrice ||
            parseFloat(product.Price.replace(/\s/g, '').replace(',', '.')) >=
              parseFloat(minPrice.replace(/\s/g, '').replace(',', '.'))) &&
          (!maxPrice ||
            parseFloat(product.Price.replace(/\s/g, '').replace(',', '.')) <=
              parseFloat(maxPrice.replace(/\s/g, '').replace(',', '.')));
  
              const meetsStockCriteria = !availabilityFilter ||
              (availabilityFilter === "available" && 
                  (product.Stock === "En stock" || product.Stock === "")) ||
              (availabilityFilter === "unavailable" && 
                  (product.Stock === "Sur commande" || product.Stock === "sur comande" || product.Stock === "Sur comande"|| 
                    product.Stock === "Sur Commande" || product.Stock === "sur commande")) ||
              (availabilityFilter === "horstock" && 
                  (product.Stock === "Hors stock" || product.Stock === "Rupture de stock" || 
                   product.Stock === "En arrivage" || product.Stock === "en arrivage" || 
                   product.Stock === "en arrvage" || product.Stock === "en arrivge"));
          
        const meetsDateFilter = !dateFilter || dateFilter === 'jour' || dateFilter === 'semaine' || dateFilter === 'mois';
  
        const meetsSearchCriteria = !search || 
          product.Ref.toLowerCase().includes(search.toLowerCase()) ||
          product.Designation.toLowerCase().includes(search.toLowerCase()) ||
          product.Stock.toLowerCase().includes(search.toLowerCase()) ||
          product.Company.toLowerCase().includes(search.toLowerCase()) ||
          product.Brand.toLowerCase().includes(search.toLowerCase());
  
        return meetsPriceCriteria && meetsStockCriteria && meetsDateFilter && meetsSearchCriteria;
      });
  
      const filteredProductsByPriceChange = filteredProducts.filter((product) => {
        const priceChange = calculatePriceChange(product);
        return (
          (changeType === 'increase' && priceChange > 0) ||
          (changeType === 'decrease' && priceChange < 0)
        );
      });
  
      const sortedProductsByPriceChange = applyPriceFilter(
        filteredProductsByPriceChange
      );
  
      const filteredProductsByDate = filterProductsByDateRange(
        sortedProductsByPriceChange
      );
  
      setProducts(filteredProductsByDate);
      setPriceChangeType(changeType);
    },
    [initialProducts, minPrice, maxPrice, availabilityFilter, dateFilter, search]
  );

  const filteredProductsByAvailability = availabilityFilter
  ? availabilityFilter === "available"
    ? products.filter(
        (product) => product.Stock === "En stock" || product.Stock === ""
      )
    : availabilityFilter === "unavailable"
      ? products.filter(
          (product) => product.Stock === "Sur commande" || product.Stock === "sur comande" || product.Stock === "Sur comande"|| 
          product.Stock === "Sur Commande" || product.Stock === "sur commande"
        )
      : availabilityFilter === "horstock"
        ? products.filter(
            (product) => product.Stock === "Hors stock" || product.Stock === "Rupture de stock" || 
                         product.Stock === "En arrivage" || product.Stock === "en arrivage" || 
                         product.Stock === "en arrvage" || product.Stock === "en arrivge"
          ) 
        : products
  : products;

const modifiedProductsCount = products.length;
const availableProductsCount = products.filter(product => product.Stock === "En stock" || product.Stock === "").length;
const unavailableProductsCount = products.filter(product => product.Stock === "Sur commande" || product.Stock === "sur comande" || product.Stock === "Sur comande"|| 
  product.Stock === "Sur Commande" || product.Stock === "sur commande" ).length;
const deletedProductsCount = products.filter(product => 
  product.Stock === "Hors stock" || 
  product.Stock === "Rupture de stock" || 
  product.Stock === "En arrivage" || 
  product.Stock === "en arrivage" || 
  product.Stock === "en arrvage" || 
  product.Stock === "en arrivge"
).length;

      
  const filteredProducts = applyPriceFilter(filteredProductsByAvailability);

  const filterProductsByDateRange = (products: Product[]): Product[] => {
    const currentDate = new Date();
    let startDate = new Date(currentDate);
  
    switch (dateFilter) {
      case 'jour':
        startDate.setHours(0, 0, 0, 0);
        currentDate.setHours(23, 59, 59, 999);
        break;
      case 'semaine':
        startDate.setDate(startDate.getDate() - 7);
        break;
      case 'mois':
        startDate.setMonth(startDate.getMonth() - 1);
        break;
    }
  
    return products.filter(product => {
      return product.Modifications?.some(mod => {
        const modDate = new Date(mod.dateModification);
        return modDate >= startDate && modDate <= currentDate;
      }) ?? false; 
    });
  };
  

  const getPriceChanges = useCallback((products: Product[]) => {
    let priceIncreaseCount = 0;
    let priceDecreaseCount = 0;
    let noChangeCount = 0;
  
    products.forEach(product => {
      if (product.Modifications && product.Modifications.length > 0) {
        const lastMod = product.Modifications[product.Modifications.length - 1];
        const oldPrice = parseFloat(lastMod.ancienPrix.replace(/\s/g, '').replace(',', '.'));
        const newPrice = parseFloat(product.Price.replace(/\s/g, '').replace(',', '.'));
        if (newPrice > oldPrice) {
          priceIncreaseCount++;
        } else if (newPrice < oldPrice) {
          priceDecreaseCount++;
        } else {
          noChangeCount++;
        }
      }
    });
  
    return { priceIncreaseCount, priceDecreaseCount, noChangeCount };
  }, []);
    
 
const { priceIncreaseCount, priceDecreaseCount } = getPriceChanges(products);
const [priceChangeSortOrder, setPriceChangeSortOrder] = useState<'asc' | 'desc'>('asc');


const handlePriceChangeSort = () => {
  const newSortOrder = priceChangeSortOrder === 'asc' ? 'desc' : 'asc';
  setPriceChangeSortOrder(newSortOrder);

  const sortedProducts = filteredProducts.slice().sort((a, b) => {
    const priceChangeA = calculatePriceChange(a);
    const priceChangeB = calculatePriceChange(b);

    if (newSortOrder === 'asc') {
      return priceChangeA - priceChangeB; 
    } else {
      return priceChangeB - priceChangeA; 
    }
  });

  setProducts(sortedProducts);
};



  return (
    
    <div className={`${styles.dashboard_content} products_page product-page-inputs`}>

    <div className={styles.dashboard_content_container}>
      
      <div className={styles.dashboard_content_header}>
      <Input
            type="text"
            value={search}
            label="Chercher.."
            onChange={(e) => handleSearch(e)}
          />
           <img
      src="/icons/search.gif"
      className={styles.search_icon}/>
      </div>

      <div className={styles.dashboard_cards}>
      <DashboardComponents.StatCard
            title="Produits Modifiés"
            value={modifiedProductsCount}
            icon="/icons/update.svg"
          />
       <DashboardComponents.StatCard
            title="Produits en stock"
            value={availableProductsCount}
            icon="/icons/product.svg"
          />
              <DashboardComponents.StatCard
            title="Produits Hors stock"
            value={deletedProductsCount}
            icon="/images/suppression.png"
          />
          <DashboardComponents.StatCard
            title="Produits sur commandes"
            value={unavailableProductsCount}
            icon="/icons/product.svg"
          />
           <DashboardComponents.StatCard
            title="Produits avec augmentation de prix"
            value={priceIncreaseCount}
            icon="/images/plus.png"
          />
          <DashboardComponents.StatCard
            title="Produits avec diminution de prix"
            value={priceDecreaseCount}
            icon="/images/moins.png"
          />
        </div>
        <div className={styles.filter_container}>
        <div className={styles.filter_group}>       
          <select
              value={selectedCompany || "All"}
              onChange={handleCompanyChange}
            >
              <option value="All" style={{color:'gray'}}>Filtrer par concurrent </option>
              {companyOptions.map((company: string) => (
                <option key={company} value={company}>
                  {company}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.filter_group}>
            <select value={dateFilter || ""} onChange={(e) => setDateFilter(e.target.value)}>
             <option value="jour">Aujourd'hui</option>
             <option value="semaine"> Les 7 derniers jours</option>
             <option value="mois">Ce mois</option>
           </select>
         </div>
          <div className={styles.filter_group}>
            <input type="number" value={minPrice} onChange={handleMinPriceChange} placeholder='Prix min'/>
          </div>
          
          <div className={styles.filter_group}>
            <input type="number" value={maxPrice} onChange={handleMaxPriceChange} placeholder='Prix max'/>
          </div>
          {errorMessage && (
  <div className={styles.errorMessage}>
    <FaExclamationCircle className={styles.errorIcon} />
    <p>{errorMessage}</p>
  </div>
)}
          <div className={styles.filter_group}>
            <select value={priceFilter || ''} onChange={handlePriceFilterChange}>
              <option value="" style={{color:'gray'}}>Trier par prix</option>
              <option value="asc">Croissant</option>
              <option value="desc">Décroissant</option>
            </select>
          </div>

          <div className={styles.filter_group}>
            <select value={availabilityFilter || ""}
              onChange={handleAvailabilityFilterChange}
            >
              <option value="" style={{color:'gray'}}>Filtrer par disponibilité</option>
              <option value="available">En stock</option>
              <option value="horstock">Hors stock</option>
              <option value="unavailable">Sur commande</option>
            </select>
          </div>        
            <div className={styles.filter_group}>
          <select value={priceChangeType || ''} onChange={handlePriceChangeFilter}>
  <option value="" style={{color:'gray'}}>changement de prix</option>
  <option value="increase">Augmentation de prix</option>
  <option value="decrease">Diminution de prix</option>
</select></div>
          <button className={styles.reset_button} onClick={handleResetFilters}><b>X</b></button>         
        </div>
        <div>
    <img
      src="/icons/lister.svg"
      alt="Tableau"
      onClick={() => setDisplayMode('table')}
      className={displayMode === 'table' ? styles.selected_icon : styles.icon}
    />
    <img
      src="/icons/table-icon.svg"
      alt="Boîtes"
      onClick={() => setDisplayMode('box')}
      className={displayMode === 'box' ? styles.selected_icon : styles.icon}
    />
  </div>
  <button onClick={exportToXLS} className={styles.exportButton}>
  Exporter en xls 
  <img
    src="/images/xls.png"
    alt="Exporter en XLS"
    className={styles.xls_image}
  />
  <img
    src="/images/telecharger.png"
    alt="Télécharger"
    className={styles.telecharger_image}
  />
</button>
  {loadingProducts ? (
  <p style={{ textAlign: "center" }}><b>Veuillez patienter...</b></p>
) : filteredProducts.length === 0 ? (
  <p style={{ textAlign: "center", color: "red" }}><b>Aucun produit trouvé</b></p>
) : displayMode === 'table' ? (
          <table>
            <thead>
              <tr>
                <th>Image</th>
                <th>Référence</th>
                <th>Désignation</th>
                <th>Marque</th>
                <th>Disponibilité</th>
                <th>Ancien Prix</th>
                <th>Date Nouveau Prix</th>
                <th>Nouveau Prix</th>
                <th onClick={handlePriceChangeSort}>
  Écart Prix
  {priceChangeSortOrder === 'asc' && <FaArrowUp />}
  {priceChangeSortOrder === 'desc' && <FaArrowDown />}
</th>
</tr>

            </thead>
            <tbody>
            {filteredProducts.map((product, index) => {
    const priceChange = calculatePriceChange(product);
    return (
                <tr key={index}>
                  <td>
                {product.Modifications && product.Modifications.length > 0 && 
  <img src="/images/updated-table.png" alt="Modified" style={{width: '35px', height: '35px', position: 'absolute', marginLeft: '-15px'}}/>
}
 <img src={product.Image} alt={product.Designation} />
                    {product.DiscountAmount !== "Aucune remise" && (
            <p style={{  backgroundColor: "red",
            color: "white",
            position: "absolute",
            marginLeft: "-15px",
            marginTop:"-60px",
            borderRadius: "2px",}}><b>{product.DiscountAmount}</b></p>
          )}</td>
                  <td>{product.Ref}</td>
                  <td>
                      <div className="product-details-link" key={product.Ref} onClick={() => handleProductClick(product)} style={{ cursor: 'pointer' }}>
  {product.Designation.length > 30
    ? product.Designation.slice(0, 30) + "..."
    : product.Designation}
</div>
                      </td>

                  <td>{product.Brand}</td>
                  <td>
                        <span style={product.Stock === "En stock" ? { color: "green" } : product.Stock ==="En arrivage" ? { color : "blue"} : { color: "red" }}>
                          {product.Stock}
                        </span>
                      </td>
                  <td>
  {product.Modifications && product.Modifications.length > 0
    ? product.Modifications[product.Modifications.length - 1].ancienPrix
    : 'Pas de modifications'}
</td>
<td>                  
{product.Modifications && product.Modifications.length > 0
  ? new Date(product.Modifications[product.Modifications.length - 1].dateModification).toLocaleDateString()
  : 'Pas de modifications'}
</td>
 <td>{product.Price}</td>
 <td style={{ color: priceChange >= 0 ? 'green' : 'red' }}>
  {priceChange >= 0 ? `+${priceChange}` : priceChange} DT
</td>


                </tr>
    );
})}
            </tbody>
          </table>
        ) : (
          <div className={styles.dashboard_content_cards}>
            {filteredProducts.map((product, index) => (
              <div key={index} className={styles.product_box}>
                <div className="product-details-link" key={product.Ref} onClick={() => handleProductClick(product)} style={{ cursor: 'pointer' }}>
 
                <img className={styles.update_product_overlay} src="/images/upp.png" style={{width:'150px',height: '120px'}} alt="new" />
                <img src={product.Image} alt={product.Designation} />
                 <p>{product.DiscountAmount !== "Aucune remise" && (
            <p style={{   backgroundColor: "red",
            color: "white",
            position: "absolute",
            top: "180px",
            left: "65px",
            padding: "5px",
            borderRadius: "5px",}}><b>{product.DiscountAmount}</b></p>
          )}</p>
                <div>
                <p>{product.Ref}</p>
                  <h3>
                  {product.Designation.length > 30 ? product.Designation.slice(0, 30) + '...' : product.Designation}
                      </h3>
  <p><span style={{ textDecoration: 'line-through',color:'gray'}}>{product.Modifications && product.Modifications.length > 0
    ? product.Modifications[product.Modifications.length - 1].ancienPrix
    : 'Pas de modifications'}</span></p>
                    <p> <span style={{color: 'red' }}><b>{product.Price}</b></span></p>
                    <p style={product.Stock === "En stock" ? { color: "green" } : { color: "red" }}>
              {product.Stock}</p>
                  <p><b>{product.Company}</b></p>
                
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {products.length > fetched ? (
          <span className={styles.handle_more_button} onClick={__handleLoadMore}>
            Charger plus
          </span>
        ) : null}


      </div>
      {selectedProduct && (
  <ProductDetails
    product={selectedProduct}
    onClose={() => setSelectedProduct(null)}
  />
)}

    </div>

    
  );
  
};


export default Update;