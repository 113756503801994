import React, { useState, FormEvent } from "react";
import axios from 'axios'; 
import { Input } from "@components";
import styles from "./login.module.css";
import { useNavigate } from "react-router-dom";
import { FaEnvelope, FaLock } from 'react-icons/fa';


export const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string>(""); 
  const [dialogVisible, setDialogVisible] = useState(false);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      const response = await axios.post('http://app.itrend-technology.tn/api/login', {
      email: username,
        motDePasse: password
      });
      localStorage.setItem("accessToken", response.data.token);
      navigate("/dashboard");
    } catch (error: any) { 
      console.error("Login error:", error);
      if (axios.isAxiosError(error) && error.response?.status === 403) {
        setError("Votre abonnement a expiré. Veuillez renouveler votre abonnement pour accéder à l'application.");
      } else {
        setError("Identifiants incorrects");
      }
      setDialogVisible(true); 
    }
  };

  const isInputValid = () => {
    return username.trim() !== "" && password.trim() !== "";
  };

  return (
    <div className={`${styles.login_container} login-page-inputs`}>
      <img src="/images/itrend_white_logo.png" className={styles.logo} alt="Logo" />
      <div className={styles.login_form}>
        <h1>Connexion</h1>
        <form onSubmit={handleSubmit}>
          <div className={styles.input_container}>
            <FaEnvelope className={styles.icon} />
            <Input
              type="text"
              label="Email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className={styles.input_container}>
            <FaLock className={styles.icon} />
            <Input
              type="password"
              label="Mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button 
            type="submit" 
            className={isInputValid() ? styles.enabled_button : styles.disabled_button} 
            disabled={!isInputValid()}
          >
            Se connecter
          </button>
        </form>
      </div>
      {dialogVisible && (
        <div className={styles.dialog_overlay} onClick={() => setDialogVisible(false)}>
          <div className={styles.dialog}>
            <span className={styles.close_button} onClick={() => setDialogVisible(false)}><b>×</b></span>
            <p className={styles.error_message}>{error}</p>
          </div>
        </div>
      )}
    </div>
  );
};
