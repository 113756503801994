// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input_input_container__ByOWT {
    display: flex;
    flex-direction: column;
}

.input_input__LsLRE {
    padding: 0.8rem;
    font-size: 0.8rem;
    color: var(--color-black);
    border-radius: 8px;
    border: 1px solid var(--color-gray);
}

.input_error__1HF8T {
    color: #BB003B;
    font-size: 0.8rem;
    margin-top: 0.2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/Input/input.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,mCAAmC;AACvC;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".input_container {\n    display: flex;\n    flex-direction: column;\n}\n\n.input {\n    padding: 0.8rem;\n    font-size: 0.8rem;\n    color: var(--color-black);\n    border-radius: 8px;\n    border: 1px solid var(--color-gray);\n}\n\n.error {\n    color: #BB003B;\n    font-size: 0.8rem;\n    margin-top: 0.2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_container": `input_input_container__ByOWT`,
	"input": `input_input__LsLRE`,
	"error": `input_error__1HF8T`
};
export default ___CSS_LOADER_EXPORT___;
