import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const auth = localStorage.getItem("accessToken");


  if (!auth) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default PrivateRoute;
