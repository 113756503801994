// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-primary: #1c1c52;
  --color-secondary: #161645;
  --color-white: #FFFFFF;
  --color-black: #000000;
  --color-gray: #BDBDBD;
  --color-linear: linear-gradient(#1c1c52, #161645);
}

::placeholder {
  color: var(--color-gray);
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12-18 */
  color: var(--color-gray);
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,wBAAwB;EACxB,0BAA0B;EAC1B,sBAAsB;EACtB,sBAAsB;EACtB,qBAAqB;EACrB,iDAAiD;AACnD;;AAEA;EACE,wBAAwB;EACxB,UAAU;EACV,YAAY;AACd;;AAEA;EACE,eAAe;EACf,wBAAwB;AAC1B","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n:root {\n  --color-primary: #1c1c52;\n  --color-secondary: #161645;\n  --color-white: #FFFFFF;\n  --color-black: #000000;\n  --color-gray: #BDBDBD;\n  --color-linear: linear-gradient(#1c1c52, #161645);\n}\n\n::placeholder {\n  color: var(--color-gray);\n  opacity: 1;\n  /* Firefox */\n}\n\n::-ms-input-placeholder {\n  /* Edge 12-18 */\n  color: var(--color-gray);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
