// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_dashboard_footer__1O5IF {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 1.5em;
    background-color: var(--color-white);
    gap: 1em;
}

.footer_dashboard_footer__1O5IF>span {
    font-size: 0.9em;
    font-weight: 400;
    color: var(--color-black);
}

.footer_dashboard_footer__1O5IF>span>a {
    font-weight: 700;
    color: var(--color-primary);
}`, "",{"version":3,"sources":["webpack://./src/components/dashoboard/Footer/footer.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,oCAAoC;IACpC,QAAQ;AACZ;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,2BAA2B;AAC/B","sourcesContent":[".dashboard_footer {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 1em 1.5em;\n    background-color: var(--color-white);\n    gap: 1em;\n}\n\n.dashboard_footer>span {\n    font-size: 0.9em;\n    font-weight: 400;\n    color: var(--color-black);\n}\n\n.dashboard_footer>span>a {\n    font-weight: 700;\n    color: var(--color-primary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard_footer": `footer_dashboard_footer__1O5IF`
};
export default ___CSS_LOADER_EXPORT___;
